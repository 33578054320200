import { render, staticRenderFns } from "../NewsArticles.html?vue&type=template&id=793d3a1c&scoped=true&"
import script from "./NewsArticles.ts?vue&type=script&lang=ts&"
export * from "./NewsArticles.ts?vue&type=script&lang=ts&"
import style0 from "@/scss/components/news/_news.scss?vue&type=style&index=0&id=793d3a1c&scoped=true&lang=scss&"
import style1 from "@/scss/components/body/_body.scss?vue&type=style&index=1&id=793d3a1c&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "793d3a1c",
  null
  
)

export default component.exports