import { Vue, Component } from 'vue-property-decorator';
import { MetaInfo } from 'vue-meta';
import { titleTemplate } from '@/helpers/meta/title-template';

@Component
export default class NewsArticles extends Vue {
  metaInfo(): MetaInfo {
    return {
      title: titleTemplate(this.$t('meta.news.title').toString()),
    };
  }

  mounted(): void {
    if (this.$route.hash) {
      setTimeout((): void => {
        location.href = this.$route.hash;
      }, 100);
    }
  }

  createSlug(title: string): string {
    return title.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
  }

  articles = [
    {
      title: 'De nieuwe belegger: goed geïnformeerd, doelgericht en vrouw',
      content: `
        <p>Het is opmerkelijk dat het in de huidige tijd nog nieuws is, maar dat is het: steeds meer vrouwen gaan
        beleggen. Dus rijst direct de vraag: waarom? Is de stijging van het aantal vrouwelijke beleggers een
        trend, een hype of is het meer? Zijn vrouwen een aparte beleggersgroep, anders dan bijvoorbeeld
        jongeren of mannen? Wat is hun beleggingsprofiel en hoe presteren zij?</p>
        <h2>Sterke groei van vrouwen in het groeiend aantal beleggers</h2>
        <p>Beleggen is weer populair. Sinds 2017 neemt de belangstelling voor beleggen flink toe. Afgelopen jaar
        groeide dit het sterkst sinds de jaren negentig. Het aantal beleggende huishoudens in Nederland
        groeide in 2020 met 17% tot 1,75 miljoen. Dit is 22% van de huishoudens en ligt niet ver onder de piek
        van 25% in de jaren negentig. Dit blijkt uit onderzoek van <a href="https://www.tns-nipo.com/nieuws/persberichten/aantal-beleggende-huishoudens-in-2020-onverwacht-h" target="_blank">Kantar</a>.</p>
        <p>Bij de mobiele broker Bux zijn de nieuwe instromers steeds vaker vrouw. In 2020 is het aantal vrouwen
        dat bij de beleggingsapp een beleggingsrekening opende verzesvoudigd. Gedurende het jaar steeg het
        aandeel vrouwen in de nieuwe beleggers bij Bux in Nederland van 13% naar 25%. Deze cijfers delen zij in
        <a href="https://www.businessinsider.nl/2020-vrouwen-beleggen-bux-fire/" target="_blank">Business Insider</a>. <a href="https://www.telegraaf.nl/gesponsord/1330440/steeds-meer-vrouwen-gaan-beleggen" target="_blank">De Telegraaf</a> meldt dat in 2017 één op de zeven beleggers vrouw was. Vijf jaar eerder
        was dit één op de acht. Volgens het <a href="https://www.ad.nl/geld/janneke-belegde-20-000-euro-en-heeft-dat-nu-verdrievoudigd-duurde-lang-voor-ik-durfde~ad347230/?referrer=https%3A%2F%2Fwww.google.com%2F" target="_blank">AD</a> is een kwart van de particuliere beleggers op de beurs vrouw.</p>
        <h2>Meer vrouwelijke beleggers is eigenlijk geen verrassing</h2>
        <p>De toename van het aandeel vrouwelijke beleggers hangt samen met de veranderde positie van de
        vrouw. Vrouwen zijn steeds hoger opgeleid. En zij zijn steeds <a href="https://www.telegraaf.nl/gesponsord/1330440/steeds-meer-vrouwen-gaan-beleggen" target="_blank">beter economisch onderlegd en meer
        financieel bewust</a>. Ook zij streven naar financiële vrijheid. En stappen logischerwijs de beleggingsmarkt
        op, het van oudsher mannenbolwerk.</p>
        <p>Tegelijkertijd <a href="https://www.businessinsider.nl/2020-vrouwen-beleggen-bux-fire/" target="_blank">ontdekken de beleggingsplatformen de vrouwelijke belegger</a>. Zij richten zich doelgericht
        op vrouwen en ook dat heeft effect.</p>
        <h2>Beleggen met een duidelijk beleggingsprofiel</h2>
        <p>Het beleggingsprofiel van de vrouwelijke belegger is anders dan het profiel van de mannelijke belegger.</p>
        <p>Het <a href="https://www.businessinsider.nl/2020-vrouwen-beleggen-bux-fire/" target="_blank">gebruiksgemak van de low cost online platformen spreekt vrouwen erg aan</a>. Niet alleen Bux ziet een
        toename van het aandeel vrouwen, <a href="https://www.businessinsider.nl/2020-vrouwen-beleggen-bux-fire/" target="_blank">ook bij platformen zoals het Israëlische eToro en Britse Nutmeg
        stijgt het aantal vrouwelijke leden flink</a>.</p>
        <p>Vrouwen nemen minder risico dan mannen op de beleggingsmarkt. Daar zijn onder andere <a href="https://www.abnamro.nl/nl/prive/themas/vermogen/artikelen/beleggen-verschil-tussen-man-en-vrouw.html" target="_blank">ABN Amro</a>,
        <a href="https://langzaamrijker.nl/beleggen/beleggen-voor-vrouwen/" target="_blank">Tim van Langzaam Rijker</a> en <a href="https://www.esquire.com/nl/the-good-life/a33792636/beleggen-aandelen-vrouwen-beter/" target="_blank">Esquire</a> het over eens. In vergelijking met de mannelijke belegger uit zich dit
        op verschillende manieren.</p>
        <ul>
            <li>Vrouwen kiezen voor meer traditionele beleggingen met gelijkmatige koersontwikkeling;</li>
            <li>vermijden producten met potentieel hoog rendement zoals derivaten, opties en turbo’s;</li>
            <li>investeren relatief minder geld; maken dus kleinere winsten, maar ook kleinere verliezen;</li>
            <li>spreiden hun beleggingen meer.</li>
        </ul>
        <p>Dit profiel komt overeen met het profiel van veel beginnende beleggers. Misschien dat met de groei van
        het aantal ervaren vrouwelijke beleggers het verschil in beleggingsstrategie tussen man en vrouw
        verdwijnt. Maar het kan ook zijn dat de vrouw vasthoudt aan haar aanpak, want de resultaten zijn
        prima!</p>
        <h2>Beleggen met goede resultaten en minder risico</h2>
        <p>Beleggingsprofielen zijn interessant voor aanbieders van beleggingsproducten, of dit nu aandelen,
        vastgoed of obligaties zijn. Beleggingsfondsen, -platformen en banken analyseren hun doelgroepen en
        klanten. Ook de vrouwelijke belegger wordt onder de loep genomen.</p>
        <p>Minder risico leidt over het algemeen tot een lager rendement. Maar blijkbaar niet altijd. Vrouwen
        nemen minder risico op de beleggingsmarkt. Toch is het gemiddelde rendement dat zij behalen hoger
        dan van de mannelijke beleggers. <a href="https://www.iexgeld.nl/Artikel/499362/Vrouwen-beleggen-anders-en-beter-dan-mannen.aspx" target="_blank">IEX</a> meldt dat uit een grootschalig onderzoek van de Britse Warwick
        University blijkt dat vrouwen jaarlijks gemiddeld een 1,8% hoger rendement behalen dan mannen.
        Hiervoor zijn verschillende verklaringen te vinden.</p>
        <p>Vrouwen maken <a href="https://langzaamrijker.nl/beleggen/beleggen-voor-vrouwen/" target="_blank">beter onderbouwde keuzes</a>, omdat zij meer onderzoek doen. En zij schakelen <a href="https://www.abnamro.nl/nl/prive/themas/vermogen/artikelen/beleggen-verschil-tussen-man-en-vrouw.html" target="_blank">eerder
        een beleggingsadviseur</a> in. Daarnaast hebben vrouwen vaker een <a href="https://www.abnamro.nl/nl/prive/speciaal-voor/preferred-banking/vermogensvragen/waarom-beleggen-vrouwen-goed-idee.html" target="_blank">langetermijndoelstelling</a>. Over het
        algemeen handelen zij minder emotioneel en reactief op de actualiteit. Zij gaan minder voor de snelle
        winst en kiezen vooral voor de buy-and-hold strategie. Dit scheelt ook in de transactiekosten.</p>
        <p>En deels kan het hogere rendement komen <a href="https://langzaamrijker.nl/beleggen/beleggen-voor-vrouwen/" target="_blank">door de bewuste keuze die vrouwen maken</a>. Vrouwen die nu
        beginnen met beleggen, doen dat met interesse en heel doelbewust. Terwijl de ‘gemiddelde’ man meer
        automatisch is gaan beleggen, omdat het vanzelfsprekend was.</p>
        <h2>Op naar een duurzame en tevreden wereld?</h2>
        <p>Vrijheid en burgerlijke gelijkheid leiden tot een gelukkige bevolking. <a href="https://businessam.be/in-gelukkigste-land-ter-wereld-meeste-vrouwen-op-beurs/" target="_blank">Business AM</a> meldt dat de
        Scandinavische landen en vooral Finland hoog op de geluksladder staan. En het blijkt dat in deze landen
        het aandeel vrouwelijke beleggers het hoogst is.</p>
        <p>Verder blijkt uit onderzoek van het Zwitserse USB dat 88% van de <a href="https://www.abnamro.nl/nl/prive/speciaal-voor/preferred-banking/vermogensvragen/waarom-beleggen-vrouwen-goed-idee.html" target="_blank">vrouwen het belangrijk vindt te
        beleggen in duurzame bedrijven</a>.</p>
        <p>Dus de vrouwelijke belegger behaalt met minder risico meer rendement, draagt bij aan een duurzame
        wereld en is een indicator voor een tevreden maatschappij. Voldoende reden om deze nieuwe belegger
        in de gaten te houden!</p>
      `,
      expanded: false,
      image: 'article12.jpg',
    },
    {
      title: '‘Growth investing’: Wat is het en hoe kun je ermee starten?',
      content: `
        <p>Fondsbeleggers, beurshandelaren en beleggers die voor eigen rekening investeren maken gebruik van diverse verschillende beleggingsstrategieën. Het is als (beginnend) belegger dan ook goed om uit te zoeken welke strategie het beste past bij jouw doelstellingen, financiële situatie en persoonlijkheid. Zo kun je het risico zo klein mogelijk houden en ben je berekend op een eventueel verlies. Eén strategie die momenteel erg aan populariteit wint is ‘growth investing’. Maar wat houdt ‘growth investing’ precies in?</p>
        <p>Bij deze beleggingsstrategie zoeken beleggers naar zogenaamde ‘growth stocks’: aandelen die naar verwachting sneller zullen groeien dan anderen. Deze strategie wordt gebruikt om sectoren, industrieën of bedrijven te identificeren met een bovengemiddeld potentieel voor groei. De verwachting is dan ook dat deze bedrijven gedurende een periode sneller zullen groeien dan andere bedrijven in de markt. Beleggers die zich met deze strategie bezighouden spreken in dit opzicht van ‘outperforming assets’.</p>
        <p>In hun zoektocht naar de juiste growth stocks richten beleggers zich doorgaans op bedrijven die een sterke focus hebben op groei en hun tijd, inkomsten, winsten en andere middelen inzetten om hun marktaandeel uit te breiden. Gezien het feit dat deze bedrijven het grootste deel van hun inkomsten weer terug in het bedrijf steken, worden er op growth stocks geen dividenden uitgekeerd. Beleggers die deze strategie volgen zoeken dus altijd naar bedrijven die een hoog rendement op hun investeringskapitaal bieden.</p>
        <p>Er zijn meerdere manieren om potentiële groei van aandelen (oftewel growth stocks) vooraf te bepalen. Welke goed bij jou zou passen, hangt af van je kennis, ervaring en beschikbare middelen. De vuistregel is om de omvang van je beleggingen en het risico laag te houden totdat je meer vertrouwen en ervaring hebt opgebouwd. Daarnaast moet je bepalen hoe passief of actief je bezig zou willen zijn in het verhandelen van growth stocks. Ook daar zijn weer veel keuzes in mogelijk.</p>
      `,
      expanded: false,
      image: 'article11.png',
    },
    {
      title: 'Beleggen: leidt de ‘buy low and sell high’ strategie gegarandeerd tot succes?',
      content: `
        <p>Er zijn veel strategieën om te beleggen voor later. Voordat je in een strategie duikt is het goed om voor jezelf duidelijk te maken waarom je gaat beleggen. Welke beleggingsstrategie dan het beste bij je past, kan afhangen van je financiële situatie, doelstellingen en karakter. Eén bekende beleggingsstrategie is de ‘buy low and sell high’. De naam dekt de lading, maar is dit dé manier tot succes? Wij leggen het je uit.</p>
        <p>‘Buy low and sell high’ is ook wel bekend onder ‘value investing’ en houdt in dat je aandelen koopt wanneer ze laag staan en naar jouw idee ondergewaardeerd zijn. Je verkoopt de aandelen pas weer als die hun achterstand hebben ingehaald. Op deze manier kan je een hoger rendement realiseren dan met een passieve vorm van beleggen, maar het risico bij deze beleggingsstrategie ligt ook hoger.</p>
        <p>Over het algemeen doen de meesten het dan ook vaak andersom: buy high, sell low. In de praktijk willen wij als mens namelijk meer van de dingen die goed voelen en minder van de dingen die pijn doen. Zo wordt er massaal geïnvesteerd wanneer de markten omhoog schieten en iedereen rijker lijkt te worden. Er wordt dus gekocht wanneer de markt positief is. Maar, wanneer er paniek ontstaat en de koersen dalen, dan wil men over het algemeen ook weer zo snel mogelijk van hun aandelen af voordat ze nog minder waard worden.</p>
        <p>Dit geeft aan dat de ‘buy low and sell high’ beleggingsstrategie in de praktijk veel aandacht en doorzettingsvermogen vereist. Zo moet je ten alle tijden de timing van de markt in de gaten houden om te bepalen wanneer je je ondergewaardeerde aandelen koopt. Daarnaast moet je over genoeg doorzettingsvermogen beschikken om je aandelen niet weer te verkopen wanneer ze ineens veel minder waard worden. De verleiding om vanuit paniek te handelen is bij deze beleggingsstrategie dus groot. Ook zul je veel moeten lezen, rekenen en uitzoeken om te zorgen dat je op het juiste moment in- en uitstapt.</p>
        <p>De ‘buy low and sell high’ beleggingsstrategie  is dus wat complexer dan misschien in eerste instantie gedacht. Of deze beleggingsstrategie tot een succes leidt, hangt alles af van of de tijd en kennis die je toepast op jouw aandelenportefeuille ervoor zorgt dat je de markt kan verslaan.</p>
      `,
      expanded: false,
      image: 'article10.jpg',
    },
    {
      title: 'Beleggen: Wat houdt de ‘buy and hold’ strategie in?',
      content: `
        <p>Een goede beleggingsstrategie is goud waard. Gelukkig zijn er diverse strategieën om te beleggen voor later. De kracht van een strategie is dat er duidelijke richtlijnen zijn waarbinnen keuzes gemaakt worden. Het is als (beginnend) belegger dan ook goed om uit te zoeken welke strategie het beste past bij jouw doelstellingen, financiële situatie en persoonlijkheid. Zo kun je het risico zo klein mogelijk houden en ben je berekend op een eventueel verlies. Benieuwd welke strategie het best aansluit bij jouw wensen? Wij leggen er de komende tijd een aantal uit. </p>
        <p>De ‘buy and hold’ strategie wordt vaak toegepast door beleggers. Bij deze strategie worden er aandelen of participaties gekocht voor de lange termijn, zoals bijvoorbeeld 15 jaar. Er wordt bij ‘buy and hold’ dus pas verkocht na de vooraf vastgestelde termijn of pas als het geld na die termijn nodig is. </p>
        <p>Deze strategie kan zowel met individuele aandelen als met beleggingsfondsen of ETF’s. Een voordeel van beleggen in beleggingsfondsen en ETF’s is dat de portefeuille in meer of mindere mate gemanaged wordt. Zodra ‘buy and hold’ als strategie wordt gebruikt voor het kopen van individuele aandelen, wordt er vrijwel altijd een fundamentele analyse gedaan om de juiste aandelen te selecteren. Bij zo’n fundamentele analyse wordt onder andere gekeken naar dividend, omzetgroei, schulden, cashflow en koerswinstverhouding.</p>
        <p>De reden dat men voor deze strategie kiest, is omdat zij weinig last willen hebben van kleine (of grote) schommelingen. Als deze schommelingen eruit gefilterd worden, wordt de groei bepaald door de daadwerkelijke groei van bedrijven en niet door wat er op internet allemaal gezegd wordt of door wat er ‘viral’ gaat op dat moment.</p>
        <p>Het behouden van de aandelen is dus erg belangrijk. Dit betekent dat je niks doet als de prijzen kelderen, maar ook dat je niks doet wanneer de prijzen enorm hoog zijn en je misschien al 20 of 30% winst hebt gemaakt.</p>
        <p>Enkele voordelen van deze beleggingsstrategie zijn dat je bij aandelen van een beleggingsfonds of ETF een beperkte hoeveelheid huiswerk hoeft te doen, dit wordt meer bij het aankopen van individuele aandelen. Andere voordelen zijn dat de markt niet getimed hoef te worden en dat de dividend door de jaren heen inkomsten oplevert. </p>
        <p>Maar deze beleggingsstrategie heeft natuurlijk ook nadelen. Nadelen kunnen zijn dat het een lange termijn commitment vereist en dat er misschien geen of te laat afscheid genomen wordt van verliesgevende aandelen in de portefeuille. Ook vereist het de nodige stalen zenuwen in een turbulente periode op de beurs.</p>
      `,
      expanded: false,
      image: 'article9.jpg',
    },
    {
      title: 'Beginnende belegger: Welke vorm van beleggen past het best bij jou?',
      content: `
        <p>Er zijn veel manieren waarop je je geld kan beleggen. Voor de beginnende belegger leggen wij vandaag vier vormen van beleggen uit. Welke vorm past het best bij jou?</p>
        <h2>1. Beleggingsfonds</h2>
        <p>Een beleggingsfonds kan worden gezien als een ‘verzamelpunt’ voor beleggers. Zo kun je als belegger aandelen in een fonds (eigenlijk een grote pot) kopen, dat in totaal het fondsvermogen wordt genoemd. Op deze manier brengen beleggers collectief geld of andere goederen in het fonds en kopen zij daarmee indirect, maar wel gezamenlijk, aandelen, obligaties, onroerend goed, grondstoffen, etc.</p>
        <p>Het fondsvermogen wordt beheerd door een fondsmanager – of soms een team van managers. Zij beslissen bijvoorbeeld of aandelen in Bedrijf X moeten worden gekocht en/of aandelen in Bedrijf Y moeten worden verkocht. Op deze manier wordt een beleggingsfonds actief gemanaged, en daar horen beheerkosten bij.</p>
        <p>Veel mensen komen op deze manier, via hun eigen bank, in aanraking met beleggen. Door het beheer zitten er echter wel kosten aan vast, vaak 1-2%per jaar.</p>
        <h2>2. Indexfonds</h2>
        <p>Anders dan een actief beheerd beleggingsfonds, zijn er passief beheerde beleggingsfondsen, oftewel indexfondsen. Indexfondsen streven ernaar hetzelfde rendement als de index te behalen in plaats van te proberen die te verslaan. Een aandelenindex is het gewogen gemiddelde van een aantal belangrijke aandelen, zoals de AEX.</p>
        <p>Indexfondsen passen hun beleggingen alleen aan als de samenstelling vande index wijzigt, veelal twee of vier keer per jaar. Indexfondsen hebben geen actieve beheerders en analisten nodig en voeren weinig transacties uit. Hierdoor hebben indexfondsen (veel) lagere kosten dan actief beheerde beleggingsfondsen.</p>
        <h4>3. ETFs</h4>
        <p>Exchange Traded Funds (ETFs), passieve fondsen en trackers: het zijn verschillende namen voor hetzelfde product. ETFs combineren de voordelen van een beleggingsfonds met die van aandelen. Technisch gezien is een ETF een beleggingsfonds waarin alle aandelen van een index zijn opgenomen in een identieke verhouding. Hierdoor is een ETF nagenoeg gelijk aan de stand van een index of een fractie daarvan.</p>
        <p>Je kan ETFs zien als een ‘geautomatiseerd’ groepje aandelen, dat passief gemanaged wordt met beheerkosten. Een ETF is op dezelfde manier verhandelbaar als een aandeel, daarom zitten er vaak ook transactiekosten aan verbonden.</p>
        <p>ETFs worden gezien als een ideaal beleggingsinstrument om mee te beginnen als startende belegger. U kunt met ETFs op een laagdrempelige manier aan uw vermogensgroei werken. Zo zijn ze transparant, kennen ze lage beheerkosten en een goede spreiding.</p>
        <h4>4. (Losse) aandelen</h4>
        <p>Besluit je zelf losse aandelen te kopen, dan ben je voor een heel klein stukje mede-eigenaar van het bedrijf waarin je belegt. Aandelen hebben transactiekosten maar geen beheerkosten. Voordat je losse aandelen koopt, kun je het best ook onderzoek doen naar het bedrijf. Zo kun je een technische of fundamentele analyse doen, en bijv. de schulden, cash, omzet, kosten en winst van een bedrijf analyseren. Denk ook na: gebruiken mensen het product of de dienst over 20 jaar nog? Hoe verdient het bedrijf z’n geld en wat is hun concurrentievoordeel? Is het bedrijf recessiebestendig? Het beleggen in aandelen vereist over het algemeen de nodige interesse en kennis.</p>
        <h4>Beheerkosten vs. risico</h4>
        <p>Als je belegt in één aandeel of een paar aandelen dan loop je een risico als deze opeens fors dalen. Je kunt dan in een korte tijd veel geld verliezen. Echter, ben je bij aandelen geen percentage kwijt aan actieve beheerkosten. Bij een beleggingsfonds betaal je dit wel, maar worden er indusdanig veel aandelen belegd dat als er een paar dalen, er ook altijd wel weer een paar stijgen. Hier betaal je dus actieve beheerkosten, maar is het financiële risico gespreid. Beleggen in indexfondsen en ETFs zitten precies tussen de aandelen en beleggingsfondsen in. Aan jou dus de keus om te bepalen wat het beste bij je past!</p>
      `,
      expanded: false,
      image: 'article8.jpg',
    },
    {
      title: 'Beleggen nog steeds populair onder jongeren',
      content: `
        <p>Het aandeel millennials dat zich op de beleggingsmarkt begeeft, is in de afgelopen jaren toegenomen. Hoe komt dit en waarin verschilt hun stijl van beleggen van die van oudere beleggers? En wat betekenen deze ontwikkelingen en de voorkeuren van deze generatie, voor de toekomst van beleggen?<p>
        <h2>Beleggen populair in 2020</h2>
        <p>Het aantal huishoudens dat belegt is in 2020 met 17% gestegen, volgens het jaarlijkse onderzoek van <a href="https://www.tns-nipo.com/" target="_blank">Kantar TNS</a>. De coronacrisis geeft mensen meer tijd om zich te verdiepen in aandelen. Een dergelijke groei hebben we dan ook sinds de jaren 90 niet meer gezien. Concreet beleggen 1,75 miljoen, ofwel 22% van het totaal aantal Nederlandse huishoudens.</p>
        <h2>Groeiende trend onder jongeren</h2>
        <p>Al een aantal jaar eerder meldde <a href="https://www.rtlnieuws.nl/economie" target="_blank">RTL Z</a> dat beleggen onder jongeren ook toenam in populariteit. Hun aandeel was flink gestegen. In het afgelopen jaar is dat aandeel volgens het onderzoek van Kantar gelijk gebleven: ongeveer 13%. Het percentage jonge beleggers bij de verschillende financiële instellingen verschilt wel aanzienlijk tussen de verschillende financiële aanbieders. Zo is de helft van de belegger bij DeGiro onder de 35 jaar, meldt de <a href="https://www.telegraaf.nl/" target="_blank">Telegraaf</a>. <a href="https://www.robeco.nl/" target="_blank">Robeco</a> heeft het over een percentage van 45%.</p>
        <h2>Redenen waarom jongeren beleggen</h2>
        <p>De belangrijkste reden waarom jongeren beleggen, is dezelfde als voor oudere beleggers: de lage rentestand. Veel beleggers zoeken een manier om meer rendement te behalen op hun spaargeld.</p>
        <p>Daarnaast helpen de technologische ontwikkelingen om in aandelen te stappen. Was het vroeger nogal omslachtig om zelf je aandelenportefeuille te beheren, tegenwoordig is dat een stuk eenvoudiger dankzij de digitalisering van identificatie, online brokers en praktische apps. Met 1 klik beheer je je beleggingen, waar je vroeger je tussenpersoon via de post of telefonisch instructies moest verstrekken. Technologie maakt beleggen een stuk toegankelijker en sneller voor </p>
        <p>Daarbij zien jongeren het beleggen als een extra vaardigheid die hen van pas kan komen, en waarmee ze direct resultaat boeken.</p>
        <p>Ook zijn jongeren vaak genoodzaakt om actief vermogen op te bouwen, omdat zij te maken hebben met minder financiële zekerheid dan de generatie voor hen. Ze starten vaak met een studieschuld en hebben minder vastigheid omdat flexcontracten veel gebruikelijker zijn. Het vertrouwen dat ouderen hebben in hun werkgever, pensioenfondsen en andere financiële dienstverleners, ontbreekt veelal bij jongeren. Zij zoeken andere manieren om een financiële buffer op te bouwen.</p>
        <p>Een laatste reden die de drempel verlaagt voor jongeren zijn de mogelijkheden om met minder kapitaal al te beleggen tegen lagere kosten. Deze trend is al enige jaren zichtbaar, met name dankzij nieuwkomers in de financiële sector, die vaak uitsluitend digitaal bestaan.</p>
        <h2>Verschil in beleggen</h2>
        <p>Jongeren beleggen anders dan ouderen, volgens <a href="https://www.iexprofs.nl/" target="_blank">IEX Profs</a>: “Ze kiezen vaker voor individuele aandelen in plaats van een indexfonds, maken geen gebruik van beleggingsadviseurs, nemen grote risico’s, zelfs in een dalende markt, en hebben een voorliefde voor duurzame bedrijven.” Volgens het blad trekken ze zich minder aan van de klassieke beoordelingscriteria zoals de koers-winstverhouding of het dividendverleden. Aanbevelingen van analisten interesseren hen niet. Jongeren varen meer hun eigen koers en kijken vooral naar waar een bedrijf voor staat, en of hen dat aanstaat. Ook houden ze minder lang vast aan aandelen en verkopen ze deze sneller weer door.</p>
        <p>Oudere beleggers huiveren van deze aanpak. Het feit dat jongeren een risico durven te nemen is juist goed, volgens Robinhood-ceo Vlad Tenev. Het zorgt voor een bodem onder de markt, omdat de profs eerder in paniek raken en uitstappen.</p>
        <h2>Beleggen in vastgoed</h2>
        <p>Voor jongeren valt er echter nog veel te bereiken in de wereld van het vastgoedbeleggen. Het nadeel van direct investeren in vastgoed is dat je een aardig startkapitaal nodig hebt. Omdat het kopen van een huis voor jongeren vaak financieel (nog) niet haalbaar is, worden zij gelimiteerd tot andere manieren om te investeren in vastgoed. Dit kan eventueel via beursgenoteerde vastgoedaandelen of deelname in vastgoedfondsen die investeren in een combinatie van winkelcentra, woningen en kantoren. Vergeleken met beursgenoteerde aandelen was de instap in vastgoedfondsen altijd aan de hoge kant, wat jongeren kan belemmeren om te starten met beleggen in vastgoed. Hier komt echter verandering in. Zo kun je met het nieuwe investeringsportaal Pebbles al beleggen in vastgoed(fondsen) vanaf een instapbedrag van € 250.</p>
        <h2>Toekomst</h2>
        <p>De huidige economie, gekenmerkt door flexibele arbeidscontracten en minder financiële zekerheden, zou ertoe kunnen leiden dat meer mensen op zoek gaan naar manieren om hun financiële middelen te laten renderen. De opkomst van nieuwe financiële platformen en het gemak van digitale identificatie heeft voor de jonge generatie het beleggen toegankelijker gemaakt. Zij zijn gewend aan het digitale leven en hebben daardoor vertrouwen in dergelijke digitale ontwikkelingen. Ook zijn ze gewend zichzelf te verdiepen in onderwerpen en kennis te delen met peers via social media. Zo leren ze meer over beleggen. De opkomst van financefluencers is een trend die daaraan bijdraagt (<a href="https://www.evajinek.nl/" target="_blank">website Eva Jinek</a>).</p>
        <p>Op basis van deze ontwikkelingen is de verwachting dat het aandeel jongeren dat belegt, blijft toenemen en dat dit impact zal hebben op de aandelenmarkt. Uiteindelijk zullen ook de jongere ‘volgers’ instappen: zij het wellicht in meer stabiele markten zoals vastgoedportefeuilles of de NPEX. Aanbieders kunnen dan inspringen op deze vraag en het aanbod op financiële platforms uitbreiden.</p>
      `,
      expanded: false,
      image: 'article1.jpg',
    },
    {
      title: 'Krappe huurmarkt maakt een goede investeringsmarkt',
      content: `
        <p>Overweeg jij in vastgoed te gaan beleggen? Dan ben je vast bekend met de verschillende soorten vastgoed. Minder bekend is misschien waarom beleggen in woningen zo interessant is.</p>
        <p>Bij investeren in woningen beleg je in huurwoningen. Op de huurwoningmarkt is sprake van krapte, een tekort. Er is veel meer vraag dan aanbod. En de vraag blijft toenemen met de groei van de bevolking en het aantal huishoudens. Huurwoningen zijn dus gewild.</p>
        <p>Diversiteit is het toverwoord bij beleggen en de woningmarkt is absoluut divers. Er zijn veel deelmarkten en type woningen. Denk aan studentenwoningen, starterswoningen, luxe appartementen en eengezinswoningen. Met zoveel type woningen kun je dus gespreid beleggen om het risico te verlagen.</p>
      `,
      expanded: false,
      image: 'article2.jpg',
    },
    {
      title: 'Het risicoprofiel van beleggen op de woningmarkt',
      content: `
        <p>Hoe meer risico hoe hoger het mogelijke rendement en andersom. Het is dus van belang te weten wat het risicoprofiel van beleggen op de woningmarkt is.</p>
        <p>In Nederland is een groot tekort aan (huur)woningen. De jaarlijkse bouwopgave is enorm en door regelgeving en ruimtegebrek moeilijk te realiseren. Het tekort wordt nauwelijks ingelopen omdat de bevolking blijft groeien.</p>
        <p>De vraag naar huurwoningen stijgt ook door de situatie op de koopwoningmarkt. Koopwoningen worden steeds duurder en een hypotheek afsluiten steeds moeilijker.</p>
        <p>Een ander belangrijk kenmerk is dat de woningmarkt minder wordt beïnvloed door een economische recessie dan andere soorten vastgoed. En ongeacht de economische situatie, de huren mogen jaarlijks worden verhoogd met de inflatie.</p>
        <p>Desondanks worden als belangrijke risico’s bij het beleggen in woningen, het risico op leegstand en waardedaling van de woning genoemd.</p>
      `,
      expanded: false,
      image: 'article3.jpg',
    },
    {
      title: 'Grote regionale verschillen: expertise brengt uitkomst',
      content: `
        <p>Ken jij de lokale economie en infrastructuur, de demografie en de woningvoorraad van alle regio’s? Dit is namelijk wel belangrijk, want zij beïnvloeden de woningmarkt.</p>
        <p>Vooral de demografie, zoals vergrijzing en het aantal eenpersoonshuishoudens in een regio of stad is bepalend voor de woningmarkt. Maar ook de lokale economieen woningvoorraad leiden tot grote verschillen in koop- en huurprijzen. Denk aan de schaarste in de grote steden en de overloop naar de buurgemeenten. Bijvoorbeeld Diemen en Weesp rond Amsterdam, IJsselstein rond Utrecht en Zuidhorn en Haren rond Groningen.</p>
        <p>Maar niet elk omliggend gebied wordt een interessant ‘overloopgebied’. Er moet een sterke, groeiende economie zijn, werkgelegenheid en goede verbindingen met de stad.</p>
        <p>De dynamiek is groot en veel factoren beïnvloeden de woningmarkt. De fondsbeheerder volgt dit alles op de voet. Zij weegt de kansen en risico’s in de verschillende steden en regio’s af.</p>
      `,
      expanded: false,
      image: 'article4.jpg',
    },
    {
      title: 'Beleggen op een markt met toenemende vraag',
      content: `
        <p>Er is in Nederland een tekort van 331.000 woningen (4,2 procent van de woningvoorraad). De overheid wil dit terugbrengen naar 2% in 2035. Maar de uitdaging is nog groter. Er moet behalve voor dit tekort namelijk ook gebouwd worden voor de toenemende vraag naar woningen.</p>
        <p>Naar verwachting groeit Nederland in 2035 naar 18,8 miljoen inwoners. Vanzelfsprekend neemt de vraag naar woningen hierdoor toe. Tussen 2020 en 2030 moeten in totaal 845.000 woningen gebouwd worden. Dit zijn gemiddeld iets meer dan 85.000 woningen per jaar.</p>
        <p>In 2019 zijn er 71.500 nieuwbouwwoningen gerealiseerd, met nog 10.000 extra woningen door transformaties van andere gebouwen. De prognose is dat de productie van nieuwbouwwoningen in 2020 zakt naar 55.000 en in 2021 naar 50.000. Vanaf 2022 wordt herstel verwacht.</p>
        <p>De rekensom laat zien dat bouwen voor de toenemende vraag en voor het tekort een moeilijke opgave is. De grote vraag naar woningen zal voorlopig aanhouden.</p>
      `,
      expanded: false,
      image: 'article5.jpg',
    },
    {
      title: 'Risicospreiding is goed mogelijk op de woningmarkt',
      content: `
        <p>Beleggers investeren vaak in verschillende types vastgoed. Zij diversifiëren voor risicospreiding. De woningmarkt is heel divers. Er zijn verschillende deelmarkten met ieder zijn eigen kenmerken en risicoprofiel.</p>
        <p>Neem voorbeeld de starterswoningen. Met name in de grote steden is hier grote vraag naar. Starters blijven relatief kort in een woning. Maar omdat de woningen schaars zijn kennen zij toch een hoge bezetting en weinig leegstand.</p>
        <p>Een ander type woning zijn de eengezinswoningen. Vooral buiten de grote stad is hier veel vraag naar en dan vooral in economisch sterke regio’s. Deze woningen wisselen minder vaak van huurder. Zij hebben dus een lagere mutatiegraad.</p>
        <p>De woningmarkt is zeer divers. Daarom kun je via indirect beleggen in een of meerdere woningfondsen al met een relatief klein instapbedrag gespreid beleggen.</p>
      `,
      expanded: false,
      image: 'article6.jpg',
    },
    {
      title: 'Met deze 5 tips lukt sparen jou ook',
      content: `
        <p>Beleggen kun je het beste doen met geld wat je de komende tijd niet nodig zal hebben. Daarom is het belangrijk om een spaarbuffer aan te leggen, die eventuele financiële tegenslagen kan opvangen.</p>
        <p>Maar hou jij aan het eind van je geld ook nog een stuk maand over? Sparen kan lastig zijn. Wij menselijke wezens zijn nu eenmaal meestal meer op het heden gericht dan op de toekomst, waardoor we vaak het gevoel hebben dat we nu geen geld kunnen missen. Ook kan het lastig zij nom geld opzij te leggen als je niet zo veel inkomsten hebt. Gelukkig zijn er meer dan genoeg slimme manieren om geld opzij te zetten zonder dat je dat gelijk voelt in je portemonnee. Wij zetten enkele tips op een rijtje:</p>
        <h4>1. Bepaal je spaardoel(en)</h4>
        <p>Een spaardoel is een financieel doel dat je zelf opstelt en zelf bijhoudt. Je kunt het zo groot of klein niet bedenken of het kan een spaardoel worden. Dit kunnen zowel korte of lange termijndoelen zijn. Over het algemeen motiveert sparen met een concreet doel meer dan ‘zomaar’ sparen. Bepaal daarom eerst voor wat je spaart: is dit een verre reis, nieuwe auto of misschien een elektrische fiets? Voor ‘zomaar’ sparen kun je ook een spaardoel aanmaken, zoals bijvoorbeeld onvoorziene uitgaven. Zo heb je altijd een potje achter de hand voor als er iets stuk gaat en vervangen moet worden.</p>
        <h4>2. Zet extra inkomsten opzij</h4>
        <p>Heb je opeens een financiële meevaller zoals een teruggave van de belastingdienst? Of ontvang je vakantiegeld, een dertiende maand of een salarisverhoging? Probeer dan het ontvangen geld niet direct weer uit te geven, maar zet het extra bedrag op je spaarrekening.</p>
        <h4>3. Laat automatisch geld opzij zetten</h4>
        <p>Via online of mobiel bankieren kun je maandelijks een automatisch bedrag naar je spaarrekening laten overschrijven. Bijvoorbeeld gelijk op het moment dat je salaris, studiefinanciering of uitkering wordt gestort. Dit kun je gemakkelijk instellen via ‘overboekingen’ waar je bij het rekeningnummer van de overboeking, het rekeningnummer van je spaarrekening kiest. Je kunt hier ook aangeven dat je dit maandelijks wilt doen in plaats van eenmalig. Op deze manier wordt het lastiger om meer geld uit te geven dan dat je op je lopende rekening hebt staan, omdat je het dan zelf van je spaarrekening moet plunderen. Dit zorgt voor een extra reden om beter op je uitgaven te letten.</p>
        <h4>4. Start met pinsparen</h4>
        <p>Een aantal banken bieden de optie om automatisch te sparen met je pinpas. Hiermee rondt de bank de bedragen naar boven af bij alle uitgaven die je doet. Stel je voor dat je boodschappen hebt gedaan voor een bedrag van € 20,23, dan haalt je bank € 21,- van je rekening af, waarbij de 77 eurocent op je spaarrekening wordt gezet. Dit is een snelle en handige manier van besparen, voornamelijk wanneer je vaak je pinpas gebruikt. Bij sommige banken is het ook mogelijk om een bepaald percentage opzij te leggen van iedere pinpasbetaling die je doet. Door te kiezen om 1%, 5% of misschien wel 10% van je pinpasbetaling te sparen, kan je spaarbedrag snel oplopen. Doordat het om kleine bedragen gaat, groeit je spaargeld ook geleidelijk en ongemerkt.</p>
        <h4>5. Spaar via je ziektekosten verzekeraar</h4>
        <p>Dan nog een laatste tip die je misschien nog niet wist. Bij sommige ziektekostenverzekeringen kun je ervoor kiezen om maandelijks meer premie te betalen. Denk hierbij aan bijvoorbeeld € 38,50 in 10 termijnen. Deze premie wordt dan apart bewaard voor je eigen risico. Mocht je dit eigen risico niet gebruiken, dan krijg je het bedrag gewoon weer terug. Zo kun je op jaarbasis al gauw € 385,- sparen!</p>
      `,
      expanded: false,
      image: 'article7.jpg',
    },
  ]
}
